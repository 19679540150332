import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__left'>
            <div className='footer__logo'>
              <Link to='/' title='Cheema Group'>
                <Logo />
              </Link>
            </div>
            <ul className='footer__social'>
              <li>
                <Link to='https://www.instagram.com/cheemahospitality/'>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.12072 2.3999H16.2077C19.2445 2.3999 21.7279 4.85257 21.7279 7.84885V15.9139C21.7279 18.9109 19.2445 21.5999 16.2077 21.5999H8.12072C5.08384 21.5999 2.6001 18.9109 2.6001 15.9139V7.84885C2.6001 4.85257 5.08382 2.3999 8.12072 2.3999ZM12.1125 6.76143C15.0636 6.76143 17.4583 9.1562 17.4583 12.1073C17.4583 15.0587 15.0636 17.4532 12.1125 17.4532C9.16065 17.4532 6.76623 15.0587 6.76623 12.1073C6.76623 9.1562 9.16065 6.76143 12.1125 6.76143ZM12.1124 8.56728C14.0667 8.56728 15.6527 10.153 15.6527 12.1075C15.6527 14.0621 14.0666 15.6482 12.1124 15.6482C10.1575 15.6482 8.5718 14.0621 8.5718 12.1075C8.5718 10.153 10.1575 8.56728 12.1124 8.56728ZM17.2843 6.0208C17.7637 6.0208 18.1524 6.40945 18.1524 6.88849C18.1524 7.36788 17.7637 7.75653 17.2843 7.75653C16.8053 7.75653 16.4166 7.36788 16.4166 6.88849C16.4166 6.40945 16.8053 6.0208 17.2843 6.0208ZM8.78145 3.94893H15.5475C18.088 3.94893 20.1658 6.01646 20.1658 8.54295V15.3428C20.1658 17.8693 18.0881 19.9365 15.5475 19.9365H8.78145C6.24089 19.9365 4.16277 17.8693 4.16277 15.3428V8.54289C4.16277 6.01646 6.24089 3.94893 8.78145 3.94893Z" fill="white"/>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to='mailto:enquries@cheemaprojects.com'>
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.30029 5.50005C1.30029 4.91657 1.53208 4.35699 1.94466 3.94441C2.35724 3.53183 2.91682 3.30005 3.50029 3.30005H18.9003C19.4838 3.30005 20.0433 3.53183 20.4559 3.94441C20.8685 4.35699 21.1003 4.91657 21.1003 5.50005M1.30029 5.50005V16.5C1.30029 17.0835 1.53208 17.6431 1.94466 18.0557C2.35724 18.4683 2.91682 18.7 3.50029 18.7H18.9003C19.4838 18.7 20.0433 18.4683 20.4559 18.0557C20.8685 17.6431 21.1003 17.0835 21.1003 16.5V5.50005M1.30029 5.50005L11.2003 12.1L21.1003 5.50005" stroke="white" stroke-width="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </Link>
              </li>
            </ul>
            <ul className='footer__contact'>
              <li><Link to='mailto:enquries@cheemaprojects.com'>enquries@cheemaprojects.com</Link></li>
            </ul>
          </div>
          <div className='footer__right'>
            <ul className='footer__nav'>
              <li><Link to='/development/'>Developments</Link></li>
              <li><Link to='/hospitality/'>Hospitality</Link></li>
              <li><Link to='/about-us/'>About Us</Link></li>
              <li><Link to='/contact-us/'>Contact Us</Link></li>
            </ul>
            <p>At Cheema Group, we are dedicated to excellence in the property and hospitality industries across Australia. Through innovation, superior design, and outstanding service, we strive to create exceptional experiences that exceed expectations and set new benchmarks in quality and sophistication.</p>
          </div>
          <div className='footer__bottom'>
            <p>© Copyright Cheema Group {new Date().getFullYear()}</p>
            <p>Design by <Link to='https://atollon.com.au?utm_source=cheema&utm_medium=website&utm_campaign=referrer&utm_term=services' target="_blank" rel="noopener noreferrer">Atollon</Link></p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
